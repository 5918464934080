// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <Box sx={{ backgroundColor: "primary.main500" }}>
      <Container maxWidth="lg">
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              UNLEASH THE FUN WITH FREE GAMES ON SNAPOSAF
            </Typography>
            <Typography variant="h6" gutterBottom>
              Welcome to Snaposaf.com!
            </Typography>
            <Typography variant="body1" paragraph>
              Plunge into the endless ocean of online games Snaposaf offers,
              available for solo or group play. Enjoy seamless gameplay on any
              device, be it a desktop, a smartphone, or a tablet. Snaposaf is
              designed to entertain gamers of every age, ensuring memorable
              gaming moments for all.
            </Typography>
            <Typography variant="body1" paragraph>
              NO INSTALLATIONS, NO FEES - DIRECT PLAY ACCESS!
            </Typography>
            <Typography variant="body1" paragraph>
              Engage with your favorite titles instantly without the hassle of
              downloads or sign-up fees. Start your adventure with Snaposaf
              exclusives, such as: Mystic Quests, Valor Trials, Rapid Racer, and
              Ninja Loot.
            </Typography>
            <Typography variant="body1" paragraph>
              Explore fan-favorites including: Labyrinth Legends, Master of
              Chess, Warrior's Duel, Brain Teasers, and much more!
            </Typography>
            <Typography variant="body1" paragraph>
              JOIN OUR THRIVING GAME COMMUNITY!
            </Typography>
            <Typography variant="body1" paragraph>
              Eager to connect with fellow gamers? Sign up and dive into games
              like Empire Builders and Farm Kingdom. With engaging communities
              and thousands of active players, these games are among the top
              MMOs available, offering rich social experiences.
            </Typography>
            <Typography variant="body1" paragraph>
              EXPLORE OUR TOP GAME CATEGORIES!
            </Typography>
            <Typography variant="body1" paragraph>
              Snaposaf boasts an extensive collection of game genres to suit any
              gamer's taste. Delve into mind-bending puzzles, challenging card
              games, and competitive .io games. For thrill-seekers, our 2 player
              challenges and fast-paced racing games promise non-stop
              excitement.
            </Typography>
          </Box>
        </Grow>
      </Container>
    </Box>
  );
}

export default CTA;
